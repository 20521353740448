<template>
  <div>
    <base-content-management title="News" contentType="news" :enableAdd="true" :enableDelete="true" :useCover="true"></base-content-management>
  </div>
</template>

<script>
import BaseContentManagement from '../content/BaseContentManagement.vue'

export default {
  title () {
    return `News Management`
  },
  components: {
    BaseContentManagement,
  },
}
</script>

<style>

</style>
